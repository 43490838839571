import { fetchAPI } from "features/cms";
import { buildWidgetInstanceId } from "features/widgets";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCollectedData } from "shared/contexts/PageData";
import { buildSortedFeed, mergeWithBanners } from "shared/utils";

export const FETCH_INCREMENT = 7;

export const useCollectedDataRender = ({ payload }) => {
  const { locale } = useRouter();
  const instanceId = buildWidgetInstanceId("002", payload.id);
  const { events, articles, courses } = useCollectedData(instanceId, [
    { name: "events", defaultValue: [] },
    { name: "articles", defaultValue: [] },
    { name: "courses", defaultValue: [] },
  ]);

  const [limit, setLimit] = useState(FETCH_INCREMENT);
  //define start of each fetch
  const [fetchStart, setFetchStart] = useState(FETCH_INCREMENT);

  const eventsSlot = payload?.events ?? false;
  const articlesSlot = payload?.articles ?? false;
  const coursesSlot = payload?.programs ?? false;

  const promoBanners = useMemo(() => payload?.promoBanners ?? [], [payload]);

  const visiblePromoBanners = useMemo(
    () =>
      promoBanners
        .sort((a, b) => a.fixedPosition - b.fixedPosition)
        .filter((banner) => {
          return banner.fixedPosition <= limit;
        }),
    [limit, promoBanners]
  );

  const items = useMemo(() => {
    return buildSortedFeed({
      events,
      articles,
      courses,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, articles, courses]);

  const [feed, setFeed] = useState(items);

  const feedWithPromoBanners = useMemo(() => {
    return mergeWithBanners([...feed], visiblePromoBanners, "fixedPosition");
  }, [feed, visiblePromoBanners]);

  useEffect(() => {
    if (feedWithPromoBanners.length <= limit) {
      const collectedDataRender = async () => {
        const nextEvents = eventsSlot
          ? await fetchAPI(
              `/events`,
              {
                sort: "eventDate:ASC",
                populate: {
                  previewImage: "*",
                  eventCategories: {
                    populate: "deep,1",
                  },
                },
                filters: {
                  active: {
                    $eq: true,
                  },
                },
                pagination: { start: fetchStart, limit: FETCH_INCREMENT },
              },
              locale
            )
          : { data: [] };

        const nextArticles = articlesSlot
          ? await fetchAPI(
              `/articles`,
              {
                sort: "date:DESC",
                populate: {
                  articleCategories: {
                    populate: "deep,1",
                  },
                  previewImage: {
                    populate: "*",
                  },
                },
                filters: {
                  date: {
                    $lte: new Date(),
                  },
                },
                pagination: {
                  start: fetchStart * 2,
                  limit: FETCH_INCREMENT * 2,
                },
              },
              locale
            )
          : { data: [] };

        const nextCourses = coursesSlot
          ? await fetchAPI(
              `/courses`,
              {
                populate: "deep,1",
                pagination: { start: fetchStart, limit: FETCH_INCREMENT },
              },
              locale
            )
          : { data: [] };

        const temporaryFeed = buildSortedFeed({
          events: nextEvents?.data,
          articles: nextArticles?.data,
          courses: nextCourses?.data,
        });

        setFeed((currentFeed) => [...currentFeed, ...temporaryFeed]);
        setFetchStart(
          (currentFetchStart) => currentFetchStart + FETCH_INCREMENT
        );
      };

      collectedDataRender();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const isCompleted = useMemo(
    () => feedWithPromoBanners.length < limit,
    [feedWithPromoBanners.length, limit]
  );

  const renderNext = useCallback(() => {
    if (!isCompleted) {
      setLimit((limit) => limit + FETCH_INCREMENT);
    }
  }, [isCompleted]);

  return { feed: feedWithPromoBanners, isCompleted, renderNext, limit };
};
