import { Article } from "../Article";
import { Banner } from "../Banner";
import { Course } from "../Course";
import { Event } from "../Event";

export const TYPE_CARD_MAPPING = {
  banner: Banner,
  event: Event,
  article: Article,
  course: Course,
  default: Article,
};

export const TypeCard = ({ className, item }) => {
  const Card = TYPE_CARD_MAPPING[item.type] || TYPE_CARD_MAPPING.default;

  return <Card item={item} className={className} />;
};
