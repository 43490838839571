export const getColumnSize = (item, index) => {
  if (Boolean(item?.highlight)) {
    return "2-3";
  }

  switch (index) {
    case 0:
    case 1:
      return "1-2";
    default:
      return "1-3";
  }
};

export const getAdjustedColumnSize = (item, index, rawItems) => {
  if (Boolean(item?.highlight)) {
    return "2-3";
  }

  switch (true) {
    case index === 0 && rawItems?.[1]?.size === "2-3":
    case index === 1 && rawItems?.[0]?.size === "2-3":
      return "1-3";
    case index === 0:
    case index === 1:
      return "1-2";
    default:
      return "1-3";
  }
};
