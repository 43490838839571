import { useRouter } from "next/router";
import { useMemo } from "react";
import { Heading5, Icon, PageLink, Paragraph } from "shared/components";
import { formatDate } from "shared/utils";

import styles from "./Banner.module.scss";

export const Banner = ({ item, className }) => {
  const { locale } = useRouter();

  const date = useMemo(() => {
    return formatDate(item.date, locale);
  }, [item.date, locale]);

  return (
    <div className={className}>
      <PageLink link={item.link} className={styles.block}>
        <div className={styles.card}>
          <div className={styles.circle}>
            <Icon className={styles.icon} name="arrow_right" />
          </div>
          <div className={styles.date}>
            <Icon className={styles.calendar} name="calendar" />
            <Paragraph color="light">{date}</Paragraph>
          </div>
          <Heading5 color="light" className={styles.title}>
            {item.title}
          </Heading5>
        </div>
      </PageLink>
    </div>
  );
};
