import classNames from "classnames";
import { useTranslation } from "features/translations";
import { Action, Container, Icon } from "shared/components";

import { useCollectedDataRender } from "./Content002.hook.js";
import styles from "./Content002.module.scss";
import { Items } from "./Items";

export const Content002 = ({ payload }) => {
  const { t } = useTranslation();

  const { feed, isCompleted, renderNext, limit } = useCollectedDataRender({
    payload,
  });

  return (
    <div className={styles.wrapper}>
      <Container className={styles.inner}>
        <Items items={feed} limit={limit} />
        {!isCompleted && (
          <Action onClick={renderNext} className={styles.more}>
            <div className={styles.loader}>
              <div className={classNames(styles["loader-wrapper"])}>
                {t("lazyRendering")}
                <Icon className={styles.icon} name="loader" />
              </div>
            </div>
          </Action>
        )}
      </Container>
    </div>
  );
};

export default Content002;
