import { useTranslation } from "features/translations";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Heading7, PageLink, Paragraph } from "shared/components";
import { formatDate, truncateText } from "shared/utils";

import styles from "./Article.module.scss";

const DEFAULT_VISIBLE_LETTERS = 150;

export const Article = ({ item, className }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const articleDate = useMemo(() => {
    return formatDate(item?.date, locale);
  }, [item?.date, locale]);

  const truncatedDescription = useMemo(() => {
    const description = item?.description ?? "";
    return truncateText(description, DEFAULT_VISIBLE_LETTERS);
  }, [item?.description]);

  const category = item.articleCategories?.[0]?.title ?? "";

  return (
    <div className={className}>
      <PageLink directory="news" slug={item.slug} className={styles.block}>
        <div className={styles.card}>
          {category && (
            <Paragraph color="grey" uppercase className={styles.category}>
              {t(category)}
            </Paragraph>
          )}
          <Heading7 color="dark" className={styles.title}>
            {item.title}
          </Heading7>
          <Paragraph color="dark" className={styles.description}>
            {truncatedDescription}
          </Paragraph>
          <Paragraph color="grey" className={styles.date} uppercase>
            {articleDate}
          </Paragraph>
        </div>
      </PageLink>
    </div>
  );
};
