import {
  Heading5,
  Icon,
  ImageLite,
  PageLink,
  Paragraph,
} from "shared/components";
import { useViewMode } from "shared/contexts/ViewMode";

import styles from "./Event.module.scss";

export const Event = ({ item, className }) => {
  const { isDesktop } = useViewMode();

  return (
    <div className={className}>
      <PageLink directory="events" slug={item.slug} className={styles.block}>
        <ImageLite image={item.previewImage} className={styles.image} />
        <div className={styles.card}>
          <Paragraph color="light" uppercase className={styles.category}>
            {item.eventCategories?.[0]?.title ?? ""}
          </Paragraph>
          <div className={styles.bottom}>
            <Heading5 color="light" className={styles.title}>
              {item.title}
            </Heading5>
            {isDesktop && (
              <div className={styles.details}>
                <Paragraph color="light" className={styles.date}>
                  <Icon className={styles.icon} name="calendar" />
                  {item.eventTimeDescription}
                </Paragraph>
                {item?.eventPrice && (
                  <Paragraph color="light" className={styles.price}>
                    <Icon className={styles.icon} name="tag" />
                    {item.eventPrice}
                  </Paragraph>
                )}
              </div>
            )}
          </div>
        </div>
      </PageLink>
    </div>
  );
};
