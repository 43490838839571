import classNames from "classnames";
import React, { useMemo } from "react";

import { FETCH_INCREMENT } from "../Content002.hook";
import { TypeCard as Card } from "../TypeCard";
import styles from "./Items.module.scss";
import { getAdjustedColumnSize, getColumnSize } from "./Items.utils";

export const Items = ({ items, limit }) => {
  const groupedItems = useMemo(() => {
    const slicedItemsCopy = items.slice(0, limit);
    const result = [];
    let groupId = 0;

    while (slicedItemsCopy.length !== 0) {
      const group = slicedItemsCopy.splice(0, FETCH_INCREMENT);

      const transformedGroup = group
        .map((item, index) => ({
          size: getColumnSize(item, index),
          data: item,
        }))
        .map(({ data, size }, index, array) => ({
          size: getAdjustedColumnSize(data, index, array),
          data,
        }));

      result.push({
        id: groupId++,
        group: transformedGroup,
      });
    }

    return result;
  }, [items, limit]);

  return (
    <div className={styles.items}>
      {groupedItems.map(({ group, id }) => (
        <div key={id} className={styles.group}>
          {group.map(({ size, data }) => (
            <Card
              key={`${data.title}_${data.id}`}
              item={data}
              className={classNames(styles.item, styles[`size-${size}`])}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
