import { useTranslation } from "features/translations";
import { useMemo } from "react";
import { Heading4, Icon, PageLink, Paragraph } from "shared/components";
import { truncateText } from "shared/utils";

import styles from "./Course.module.scss";

const DEFAULT_VISIBLE_LETTERS = 250;

export const Course = ({ item, className }) => {
  const { t } = useTranslation();

  const truncatedDescription = useMemo(() => {
    const description = item?.description ?? "";
    return truncateText(description, DEFAULT_VISIBLE_LETTERS);
  }, [item?.description]);

  return (
    <div className={className}>
      <PageLink directory="programs" slug={item.slug} className={styles.block}>
        <div className={styles.card}>
          <div className={styles.top}>
            <Heading4 color="light" className={styles.title}>
              {item.title}
            </Heading4>
            <Paragraph color="light" className={styles.description}>
              {truncatedDescription}
            </Paragraph>
          </div>
          <div className={styles.details}>
            {item?.courseStartDate && (
              <Paragraph color="light" className={styles.date}>
                <Icon className={styles.icon} name="calendar" />
                {t("start")} {item.courseStartDate}
              </Paragraph>
            )}
            {item?.coursePrice && (
              <Paragraph color="light" className={styles.price}>
                <Icon className={styles.icon} name="tag" />
                {item.coursePrice}
              </Paragraph>
            )}
          </div>
        </div>
      </PageLink>
    </div>
  );
};
